.bonus_wrapper{
  justify-content: end;
  .bonus_container{
    margin-top: -150px;
    margin-right: 70px;
    img{
      height: 70% !important;
    }
  }
}
.btn.bonus {
  margin-top: 15px;
}
.btn.bonus {
  margin-top: 15px;
  background-color:#fac734;
}
.jackpot_wrapper{
  background: #072079;
}

.search_icon{
  span{ color: #fac734;}
}
.jackpot.name{
  color: white;
}
.jackpot.value{
  color: #fac734;
}
.header-logo {
  width: auto;
  //position: relative;
  //left: auto;
  height: 70%;
}

.bonus_slider {
  margin-top: 0;
  height: 80vh;

}
.bonus_wrapper {
  min-height: 680px;
  width: 100vw;
  height: 100vh;
  //margin-top: 60px;
  //min-height: 250px;
  max-height: initial;
}
.header-component{
  background: unset;
  backdrop-filter: unset;
  -webkit-backdrop-filter: unset;
}

.carousel-initialized{
  padding: 0 !important;
}
@media screen and (max-width: 600px) {
  .bonus_wrapper{
    justify-content: center;
    .bonus_container{
      img{
        height: 60% !important;
      }
      margin-right: 0px;
    }
  }
  .btn.bonus {
    margin-top:20px;
    height: initial;
  }

}

